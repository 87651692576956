import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-features',
    templateUrl: './features.component.html',
    styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Our Features',
            title: 'We Always Try To Understand Users Expectation',
            paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        }
    ]

    singleFeatures: singleFeaturesContent[] = [
        {
            icon: 'icofont-water-drop',
            title: 'Sprinkler Installation',
            paragraphText: 'Let us assess the area and get back to you with a quote'
        },
        {
            icon: 'icofont-fix-tools',
            title: 'Sprinkler Repair',
            paragraphText: 'Tell us the issue over the phone or we will send out a technician to assess'
        },
        {
            icon: 'icofont-checked',
            title: 'Backflow Testing',
            paragraphText: 'Need a Backflow test look no further! We can help you with that!'
        }
    ]

}

class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}

class singleFeaturesContent {
    icon : string;
    title : string;
    paragraphText : string;
}