<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <h4 style="color:white">Red Sprinkler Service</h4>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" (click)="onClick('home')">Home</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('services')">Services</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('testimonial')">Testimonial</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Contact</span></li>
            </ul>
        </div>
    </div>
</nav>