<div class="main-banner main-banner-with-form item-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <h1>Lets Start Keeping your Books True</h1>
                            <p>Creating one automation at a time!</p>
                            <div class="btn-box">
                                <a class="btn btn-primary" href="tel:+14329403000"> Call Now</a>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-video">
                            <img style="height:300px; opacity:0%" src="assets/img/vb.png" alt="img">
                            <a href="" class="video-btn popup-youtube"><i class="icofont-ui-play"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-features></app-features>

<app-testimonial></app-testimonial>

<app-contact></app-contact> 