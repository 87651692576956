<section id="testimonial" class="testimonial-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <span>What people say about us</span>
            <h2>Our Testimonial</h2>
        </div>
        <div class="testimonial-slides owl-carousel owl-theme">
            <div class="testimonial-item">
                <div class="testimonial-content">
                        <p>They installed a sprinkler system. They were courteous, professional, and answered all my concerns before the install. They did what was proposed and minimum disturbance to the landscape. My neighbors also commented on a job well done!</p>
                    <div class="client-info">
                        <h3>Robert Jackson</h3>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Professional company; did an awesome job repairing my sprinkler head and adjusting my sprinklers. Highly recommend!</p>
                    </div>
                    <div class="client-info">
                        <h3>Kelley Cordell</h3>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Monty worked on my broken irrigation system. A true professional. Great job and a great asset to this company. Would never use anyone else!

                        </p>
                    </div>
                    <div class="client-info">
                        <h3>Stephen Young</h3>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Beyond impressed with work and quality. Thank you for a wonderful experience and making it easier to water our lawn and gardens. Very impressed with response time from office as well. Thank you and highly recommend them for your system.</p>
                    </div>
                    <div class="client-info">
                        <h3>Ruben Sanchez</h3>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="testimonial-content">
                    <div class="desc">
                        <p>He was on time. He analyzed the situation & gave me suggestions on how to get the leak resolved. I appreciate his conclusion and will follow through with his recommendations. Thank you!</p>
                    </div>
                    <div class="client-info">
                        <h3>Sammy Wells</h3>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Great company to deal with!! Scheduled from 8-12pm on relatively short notice. The gentleman showed up at 8:05am and was professional, knowledgeable and a true pleasure to speak to about the repairs. He changed out many sprinkler heads, checked everything and calibrated my spray coverage perfectly. I have dealt with a few irrigation companies but Red Sprinkler Service will be my “go to” from here on!!</p>
                    </div>
                    <div class="client-info">
                        <h3>Taj Porter</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>